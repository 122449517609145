import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  getCallStats(id: number): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/agents/${id}`
    return httpService.get(url)
  },
  getTeamStats(): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/agents/teamStats`
    return httpService.get(url)
  },
}

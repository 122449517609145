<template>
  <component :is="dashboardForUserType" />
</template>

<script>
import DashboardLegacy from '@/views/DashboardLegacy.vue'
import DashboardSDR from '@/views/DashboardSDR.vue'

import { authComputed } from '@/state/helpers'

export default {
  computed: {
    ...authComputed,
    dashboardForUserType() {
      if (this.isSDR || this.isSDRManager) {
        return DashboardSDR
      }
      return DashboardLegacy
    },
  }
}
</script>

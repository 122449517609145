<template>
  <v-container
    fluid
    :class="sideBarToggled"
    :style="hasCallCenter ? 'margin-top: 60px;' : ''"
  >
    <v-layout column>
      <h1 class="page-header">Dashboard</h1>
      <v-layout align-start justify-start row fill-height class="some-space">
        <v-card class="dashboard-card" :to="{ name: 'quotes' }">
          <CRIcon
            :width="68"
            :height="68"
            color="primary"
            style="border-top-right-radius: 0"
          >
            quotes
          </CRIcon>
          <v-card-title class="card-title">Quotes</v-card-title>
        </v-card>
        <v-card class="dashboard-card" :to="{ name: 'reservations' }">
          <CRIcon color="primary" :width="68" :height="67.5">
            reservations
          </CRIcon>
          <v-card-title class="card-title">Reservations</v-card-title>
        </v-card>
      </v-layout>
      <v-layout align-start justify-start row fill-height>
        <v-card
          v-if="isSuperAdmin"
          class="dashboard-card"
          :to="{ name: 'companies' }"
        >
          <CRIcon color="primary" :width="68" :height="67.5">my_company</CRIcon>
          <v-card-title class="card-title">Companies</v-card-title>
        </v-card>
        <v-card
          v-if="isSuperAdmin"
          class="dashboard-card"
          :to="{ name: 'users' }"
        >
          <CRIcon color="primary" :width="68" :height="67.5">customers</CRIcon>
          <v-card-title class="card-title">Users</v-card-title>
        </v-card>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return {
      title: 'CharterUp',
    }
  },
  data() {
    return {
      reportingEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    sideBarToggled() {
      const state = this.getSideBar()

      if (state) {
        return 'why'
      }

      return 'not'
    },
  },
  mounted() {
    this.reportingEnabled = this.isReportAdmin()
  },
  methods: {
    ...mapGetters({
      getSideBar: 'dashboard/getSideBar',
    }),
    isReportAdmin() {
      const roles = this.currentUserProfile?.roles || []
      const isReportAdmin = roles.find((r) => r.roleName === 'is_report_admin')
      return !!isReportAdmin
    },
  },
}
</script>

<style lang="scss" scoped>
.why {
  position: absolute;
  top: -48px;
  left: 35px;
  max-width: 870px;
}

.not {
  position: absolute;
  left: 35px;
  max-width: 870px;
}

@media only screen and (max-width: 1180px) {
  .why {
    top: 0;
  }
}

.some-space {
  margin-top: 3%;
}

.dashboard-card {
  width: 380px;
  padding: 45px;
  margin: 0 30px 30px 0;
  overflow: hidden;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba($black-base, 0.09);

  .card-title {
    position: absolute;
    z-index: 5;
    display: inline;
    font-size: 23px;
  }
}
</style>

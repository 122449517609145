<template>
  <v-container fluid class="side-bar">
    <h1 class="page-header">Dashboard</h1>
    <v-layout column>
      <v-layout row wrap>
        <v-flex class="sheet dashboard-card top-left" xs12 sm6>
          <h3>Call Stats</h3>
          <v-layout row wrap>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Calls Today</h3>
              <p>{{ callStats.callsToday }}</p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Last Call</h3>
              <p>{{ lastCallDisplayText }}</p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Time per Call</h3>
              <p>{{ timePerCallDisplayText }}</p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Leads Created</h3>
              <p>{{ callStats.leadsCreated }}</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="sheet dashboard-card top-right" xs12 sm6>
          <h3>Team stats</h3>
          <v-layout row wrap>
            <v-flex class="stat" xs12 md6 lg3>
              <h3 class="d-flex align-start flex-column">Calls in queue</h3>
              <p class="d-flex align-end flex-column">
                {{ teamStats.callsInQueue }}
              </p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Agents Available</h3>
              <p>{{ teamStats.agentsAvailable }}</p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Agents on Calls</h3>
              <p>{{ teamStats.agentsOnCalls }}</p>
            </v-flex>
            <v-flex class="stat" xs12 md6 lg3>
              <h3>Longest Hold Time</h3>
              <p>{{ longestHoldDisplayText }}</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-flex xs12 class="sheet dashboard-card bottom">
        <CallsList mode="history" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import Duration from 'luxon/src/duration.js'
import agents from '@/services/agents'
import { phoneFormatFilter } from '@/utils/phone'
import CallsList from '../views/CallsList.vue'
import { authComputed, callCenterComputed } from '@/state/helpers'

export default {
  name: 'DashboardSDR',
  components: {
    CallsList,
  },
  data() {
    return {
      callStats: {
        callsToday: 0,
        averageCallLength: 0,
        leadsCreated: 0,
        sinceLastCall: 0,
      },
    }
  },
  computed: {
    ...callCenterComputed,
    ...authComputed,
    lastCallDisplayText() {
      let now = DateTime.local()
      let sinceLastCall = now.minus(
        Duration.fromObject({ minutes: this.callStats.sinceLastCall })
      )
      return this.calculateDifference(sinceLastCall)
    },
    timePerCallDisplayText() {
      return this.timeToDuration(this.callStats.averageCallLength, 'seconds')
    },
    longestHoldDisplayText() {
      return this.timeToDuration(this.teamStats.longestHoldDuration, 'minutes')
    },
  },
  async mounted() {
    const callStatsResponse = await agents.getCallStats(this.currentUser.userId)
    const callStats = callStatsResponse.data
    this.callStats = {
      averageCallLength: callStats.averageCallLength,
      callsToday: callStats.callsToday,
      leadsCreated: callStats.leadsCreated,
      sinceLastCall: callStats.sinceLastCall,
    }
  },
  methods: {
    phoneFormatFilter,
    calculateDifference(timestamp) {
      let start = DateTime.fromISO(timestamp)
      let end = DateTime.local()
      let str = ''

      let diff = end.diff(start, ['days', 'hours', 'minutes', 'seconds'])

      if (diff.days > 0) {
        str = diff.days === 1 ? `${diff.days} day ago` : `${diff.days} days ago`
      } else if (diff.hours > 0) {
        str =
          diff.hours === 1
            ? `${diff.hours} hour ago`
            : `${diff.hours} hours ago`
      } else {
        str = `${diff.minutes} min ago`
      }

      return str
    },
    timeToDuration(length, unit) {
      let duration
      if (unit === 'minutes') {
        duration = Duration.fromObject({ minutes: length })
      } else {
        duration = Duration.fromObject({ seconds: length })
      }

      let str = ''

      let hours = Math.floor(duration.as('hours'))
      let minutes = Math.floor(duration.as('minutes'))
      let seconds = Math.floor(duration.as('seconds'))

      if (hours >= 1) {
        str += `${hours}h`
        duration = duration.minus(Duration.fromObject({ hours: hours }))
        let mins = Math.floor(duration.as('minutes'))
        str += ` ${mins}m`
        duration = duration.minus(Duration.fromObject({ minutes: mins }))
        let secs = Math.floor(duration.as('seconds'))
        str += ` ${secs}s`
      } else if (minutes >= 1) {
        str += `${minutes}m`
        duration = duration.minus(Duration.fromObject({ minutes: minutes }))
        let secs = Math.floor(duration.as('seconds'))
        str += ` ${secs}s`
      } else {
        str += ` ${seconds}s`
      }

      return str
    },
  },
}
</script>

<style lang="scss" scoped>
.side-bar {
  .dashboard-card-row {
    display: flex;
  }

  .dashboard-card {
    box-sizing: border-box;
    padding: 15px;
    flex: 1;

    & > h3 {
      margin: 8px 0 15px 0;
    }

    h3 {
      font-size: 15px;
    }

    .stat {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-size: 19px;
      }
    }

    &.top-left {
      margin: 0 10px 10px 0;
    }

    &.top-right {
      margin: 0 0 10px 10px;
    }

    &.bottom {
      margin: 20px 0 0 0;
      padding: 0;
    }
  }

  .scrollable-table {
    max-height: 120px;
    overflow: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba($black-base, 0.5);
      box-shadow: 0 0 1px rgba($white, 0.5);
    }

    table {
      width: 100%;
      min-width: 700px;
    }
  }
}
</style>
